<template>
    <BackArrow :pageTitles="{ heading: 'New Request', content: 'BACK TO REQUESTS' }" />
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <success-toast :successMessage="successMessage" @resetSucessMessage="successMessage = null">
        <template v-slot:success-message>
            <span class="pl-2 text-md">{{ successMessage }}</span>
        </template>
    </success-toast>
    <main class="mx-32 my-10 p-8 rounded bg-white shadow">
        <section @click="toggleProductPurchase" class="flex text-sm">
            <!-- product purchase -->
            <div
                @click="activateProductPurchase"
                v-if="isProductPurchase"
                class="w-60 flex items-center border border-primaryColor text-primaryColor rounded p-2.5 px-5 mr-5 cursor-pointer"
            >
                <img src="../assets/images/radio-active.svg" />
                <span class="ml-2">Product Purchase</span>
            </div>
            <div
                @click="activateProductPurchase"
                v-else
                class="w-60 flex items-center border border-borderColor rounded p-2.5 px-5 mr-5 cursor-pointer"
            >
                <img src="@/assets/images/radio-inactive.svg" />
                <span class="ml-2">Product Purchase</span>
            </div>
            <!-- non-product purchase -->
            <div
                @click="activateNonProductPurchase"
                v-if="!isNonProductPurchase"
                class="w-60 flex items-center border border-borderColor rounded p-2.5 px-5 cursor-pointer"
            >
                <img src="@/assets/images/radio-inactive.svg" />
                <span class="ml-2">Non-Product Purchase</span>
            </div>
            <div
                @click="activateNonProductPurchase"
                v-else
                class="w-60 flex items-center border border-primaryColor text-primaryColor rounded p-2.5 px-5 cursor-pointer"
            >
                <img src="@/assets/images/radio-active.svg" />
                <span class="ml-2">Non-Product Purchase</span>
            </div>
        </section>
        <section class="my-8">
            <h1 class="py-6 text-lg font-bold">Item Description</h1>
            <div class="flex justify-between">
                <div class="w-4/5 mr-12 text-sm">
                    <!-- Product purchase -->
                    <div @click="setValue" v-if="isProductPurchase">
                        <div class="mb-6">
                            <div class="flex justify-between">
                                <div class="w-1/2 mr-7">
                                    <label class="block text-xs leading-loose">Item Name</label>
                                    <input
                                        v-model="productPurchaseData.itemName"
                                        type="text"
                                        class="block w-full h-11 border border-borderColor rounded py-2.5 px-4"
                                    />
                                </div>
                                <div class="w-1/2 relative">
                                    <label class="block text-xs leading-loose"
                                        >Are Goods Presently in Stock?</label
                                    >
                                    <div
                                        @click="showInStockDropdown = !showInStockDropdown"
                                        class="block w-full h-11 border border-borderColor rounded py-2.5 px-4"
                                    >
                                        {{ productPurchaseData.areGoodsPresentlyInStock }}
                                    </div>
                                    <ChevronDownIcon
                                        @click="showInStockDropdown = !showInStockDropdown"
                                    />
                                    <ul
                                        v-if="showInStockDropdown"
                                        class="absolute bg-greyBgColor w-full z-50"
                                    >
                                        <li
                                            data-goodsinstock="goodsinstock"
                                            class="py-2 px-2.5 hover:bg-borderColor"
                                        >
                                            Yes
                                        </li>
                                        <li
                                            data-goodsinstock="goodsinstock"
                                            class="py-2 px-2.5 hover:bg-borderColor"
                                        >
                                            No
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="mb-6">
                            <div class="flex justify-between">
                                <div class="w-1/2 mr-7">
                                    <label class="block text-xs leading-loose"
                                        >Deployment Location / Project</label
                                    >
                                    <input
                                        v-model="productPurchaseData.deploymentLocation"
                                        type="text"
                                        class="block w-full h-11 border border-borderColor rounded py-2.5 px-4"
                                    />
                                </div>
                                <div class="w-1/2 relative">
                                    <label class="block text-xs leading-loose"
                                        >Do we Really Need the Goods/Services</label
                                    >
                                    <div
                                        @click="showGoodsNeededDropdown = !showGoodsNeededDropdown"
                                        class="block w-full h-11 border border-borderColor rounded py-2.5 px-4"
                                    >
                                        {{ productPurchaseData.doWeReallyNeedTheGoodsOrServices }}
                                    </div>
                                    <ChevronDownIcon
                                        @click="showGoodsNeededDropdown = !showGoodsNeededDropdown"
                                    />
                                    <ul
                                        v-if="showGoodsNeededDropdown"
                                        class="absolute bg-greyBgColor w-full"
                                    >
                                        <li
                                            data-aregoodsneeded="aregoodsneeded"
                                            class="py-2 px-2.5 hover:bg-borderColor"
                                        >
                                            Yes
                                        </li>
                                        <li
                                            data-aregoodsneeded="aregoodsneeded"
                                            class="py-2 px-2.5 hover:bg-borderColor"
                                        >
                                            No
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="mb-6">
                            <label class="text-xs leading-loose">Item Description</label>
                            <div>
                                <textarea
                                    v-model="productPurchaseData.itemDescription"
                                    class="w-full p-2.5 resize-none border border-borderColor rounded"
                                ></textarea>
                            </div>
                        </div>
                        <div class="mb-6">
                            <label class="text-xs leading-loose">Procurement Strategy</label>
                            <div>
                                <textarea
                                    v-model="productPurchaseData.procurementStrategy"
                                    class="w-full p-2.5 resize-none border border-borderColor rounded"
                                ></textarea>
                            </div>
                        </div>
                        <div class="mb-6">
                            <div class="flex justify-between">
                                <div class="w-1/2 mr-7">
                                    <label class="block text-xs leading-loose"
                                        >Suggested Supplier</label
                                    >
                                    <input
                                        v-model="productPurchaseData.suggestedSupplier"
                                        type="text"
                                        required
                                        class="block w-full h-11 border border-borderColor rounded py-2.5 px-4"
                                    />
                                </div>
                                <div class="w-1/2 relative">
                                    <label class="block text-xs leading-loose"
                                        >Is Expenditure Budget?</label
                                    >
                                    <div
                                        @click="
                                            showIsExpenditureDropdown = !showIsExpenditureDropdown
                                        "
                                        class="block w-full h-11 border border-borderColor rounded py-2.5 px-4"
                                    >
                                        {{ productPurchaseData.isExpenditureBudget }}
                                    </div>
                                    <ChevronDownIcon
                                        @click="
                                            showIsExpenditureDropdown = !showIsExpenditureDropdown
                                        "
                                    />
                                    <ul
                                        v-if="showIsExpenditureDropdown"
                                        class="absolute bg-greyBgColor w-full z-50"
                                    >
                                        <li
                                            data-isexpenditurebudget="goodsinstock"
                                            class="py-2 px-2.5 hover:bg-borderColor"
                                        >
                                            Yes
                                        </li>
                                        <li
                                            data-isexpenditurebudget="goodsinstock"
                                            class="py-2 px-2.5 hover:bg-borderColor"
                                        >
                                            No
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="mb-6">
                            <div class="flex justify-between">
                                <div class="w-2/6 mr-7">
                                    <label class="block text-xs leading-loose">Unit Cost</label>
                                    <input
                                        v-model="productPurchaseData.unitCost"
                                        type="number"
                                        class="block w-full h-11 border border-borderColor rounded py-2.5 px-4"
                                    />
                                </div>
                                <div class="w-2/6 mr-7">
                                    <label class="block text-xs leading-loose">Quantity</label>
                                    <input
                                        v-model="productPurchaseData.quantity"
                                        type="number"
                                        class="block w-full h-11 border border-borderColor rounded py-2.5 px-4"
                                    />
                                </div>
                                <div class="w-2/6">
                                    <label class="block text-xs leading-loose">Total Amount</label>
                                    <div
                                        class="block w-full h-11 border border-borderColor rounded py-2.5 px-4"
                                    >
                                        {{ totalCost }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Non-product purchase -->
                    <div v-if="isNonProductPurchase">
                        <div class="mb-6">
                            <div class="flex justify-between">
                                <div class="w-1/2 mr-7">
                                    <label class="block text-xs leading-loose">Item Name</label>
                                    <input
                                        v-model="nonProductPurchaseData.itemName"
                                        type="text"
                                        class="block w-full border border-borderColor rounded py-2.5 px-4"
                                    />
                                </div>
                                <div class="w-1/2">
                                    <label class="block text-xs leading-loose">Cost</label>
                                    <input
                                        v-model="nonProductPurchaseData.cost"
                                        type="number"
                                        class="block w-full border border-borderColor rounded py-2.5 px-4"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-6">
                            <label class="text-xs leading-loose">Item Description</label>
                            <div>
                                <textarea
                                    v-model="nonProductPurchaseData.itemDescription"
                                    class="w-full py-2.5 px-4 resize-none border border-borderColor rounded"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <hr class="my-12" />
                    <button
                        v-if="isProductPurchase"
                        @click="sendProductPurchaseRequest"
                        class="text-white bg-primaryColor rounded py-2.5 px-14 font-semibold block ml-auto"
                        :class="{ 'cursor-not-allowed': submitting }"
                    >
                        Send Request
                    </button>
                    <button
                        v-if="isNonProductPurchase"
                        @click="sendNonProductPurchaseRequest"
                        class="text-white bg-primaryColor rounded py-2.5 px-14 font-semibold block ml-auto"
                        :class="{ 'cursor-not-allowed': submitting }"
                    >
                        Send Request
                    </button>
                </div>
                <div class="w-1/5">
                    <div class="draggable-element text-xs" draggable="true">
                        Supporting Documents
                    </div>
                    <div
                        id="drop-zone"
                        class="flex flex-col items-center justify-center bg-greyBgColor rounded my-2 py-14 shadow"
                    >
                        <img src="@/assets/images/cloud-icon.svg" alt="upload file" />
                        <input
                            @change="handleFileUpload"
                            type="file"
                            multiple
                            class="file-upload-input-field hidden"
                        />
                        <span class="text-lg leading-loose">Drop files here</span>
                        <span class="text-xs"
                            >or
                            <span
                                @click="addFileFromComputer"
                                class="text-primaryColor underline px-1 cursor-pointer"
                                >Browse Files</span
                            >
                            from your computer.</span
                        >
                    </div>
                    <div v-if="supportDocuments" class="text-right text-sm">
                        {{
                            supportDocuments.length > 1
                                ? `${supportDocuments.length} files`
                                : `${supportDocuments.length} file`
                        }}
                        added
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import ChevronDownIcon from '@/components/ui/ChevronDropDown.vue'
import ErrorToast from '@/components/notificationToasts/ErrorToast.vue'
import { mapState } from 'vuex'

export default {
    mounted() {
        this.resetSupportDocumentsField()
        const dropZone = document.querySelector('#drop-zone')

        dropZone.addEventListener('dragover', event => {
            event.preventDefault()
            event.dataTransfer.dropEffect = 'move'
        })

        dropZone.addEventListener('drop', event => {
            event.preventDefault()
            this.supportDocuments = event.dataTransfer.files
        })
    },
    components: {
        BackArrow,
        ChevronDownIcon,
        ErrorToast,
    },
    computed: {
        ...mapState(['user']),
        totalCost() {
            return (
                Number(this.productPurchaseData.unitCost) *
                Number(this.productPurchaseData.quantity)
            )
        },
    },
    data() {
        return {
            isProductPurchase: true,
            isNonProductPurchase: false,
            supportDocuments: null,
            showInStockDropdown: false,
            showGoodsNeededDropdown: false,
            showIsExpenditureDropdown: false,
            submitting: false,
            errorMessage: null,
            successMessage: null,
            productPurchaseData: {
                itemName: null,
                areGoodsPresentlyInStock: null,
                deploymentLocation: null,
                doWeReallyNeedTheGoodsOrServices: null,
                itemDescription: null,
                procurementStrategy: null,
                suggestedSupplier: null,
                isExpenditureBudget: null,
                unitCost: 1,
                quantity: 1,
            },
            nonProductPurchaseData: {
                itemName: null,
                cost: null,
                itemDescription: null,
            },
        }
    },
    methods: {
        resetProductPurchaseFields() {
            this.productPurchaseData.itemName = null
            this.productPurchaseData.areGoodsPresentlyInStock = null
            this.productPurchaseData.deploymentLocation = null
            this.productPurchaseData.doWeReallyNeedTheGoodsOrServices = null
            this.productPurchaseData.itemDescription = null
            this.productPurchaseData.procurementStrategy = null
            this.productPurchaseData.suggestedSupplier = null
            this.productPurchaseData.isExpenditureBudget = null
            this.supportDocuments = null
        },
        resetNonProductPurchaseFields() {
            this.nonProductPurchaseData.itemName = null
            this.nonProductPurchaseData.cost = null
            this.nonProductPurchaseData.itemDescription = null
            this.supportDocuments = null
        },
        activateProductPurchase() {
            this.resetSupportDocumentsField()
            this.resetNonProductPurchaseFields()
            this.isProductPurchase = true
            this.isNonProductPurchase = false
        },
        activateNonProductPurchase() {
            this.resetSupportDocumentsField()
            this.resetProductPurchaseFields()
            this.isProductPurchase = false
            this.isNonProductPurchase = true
        },
        addFileFromComputer() {
            this.resetSupportDocumentsField()
            const inputElement = document.querySelector('.file-upload-input-field')

            inputElement.click()
        },
        handleFileUpload(event) {
            event.preventDefault()

            this.supportDocuments = event.target.files
        },
        resetSupportDocumentsField() {
            this.supportDocuments = null
        },
        setValue(event) {
            if (event.target.dataset.goodsinstock) {
                this.productPurchaseData.areGoodsPresentlyInStock = event.target.textContent
                this.showInStockDropdown = false
            }

            if (event.target.dataset.aregoodsneeded) {
                this.productPurchaseData.doWeReallyNeedTheGoodsOrServices = event.target.textContent
                this.showGoodsNeededDropdown = false
            }

            if (event.target.dataset.isexpenditurebudget) {
                this.productPurchaseData.isExpenditureBudget = event.target.textContent
                this.showIsExpenditureDropdown = false
            }
        },
        async sendProductPurchaseRequest() {
            try {
                const purchaseDataValues = Object.values(this.productPurchaseData)

                if (purchaseDataValues.includes(null)) {
                    this.errorMessage = 'All fields are required.'
                    return
                }

                if (this.submitting) {
                    return
                }

                this.submitting = true

                const formData = new FormData()

                formData.append('product_name', this.productPurchaseData.itemName)
                formData.append(
                    'is_product_in_stock',
                    this.productPurchaseData.areGoodsPresentlyInStock
                )
                formData.append('deployment_location', this.productPurchaseData.deploymentLocation)
                formData.append(
                    'need_product',
                    this.productPurchaseData.doWeReallyNeedTheGoodsOrServices
                )
                formData.append('product_description', this.productPurchaseData.itemDescription)
                formData.append(
                    'procurement_strategy',
                    this.productPurchaseData.procurementStrategy
                )
                formData.append('suggested_supplier', this.productPurchaseData.suggestedSupplier)
                formData.append('expenditure_budget', this.productPurchaseData.isExpenditureBudget)
                formData.append('unit_cost', this.productPurchaseData.unitCost)
                formData.append('quantity', this.productPurchaseData.quantity)
                formData.append('user_id', this.user.data.id)

                if (this.supportDocuments.length) {
                    for (let i = 0; i < this.supportDocuments.length; i += 1) {
                        formData.append('supporting_documents[]', this.supportDocuments[i])
                    }
                }

                const { status, data } = await Request.postRequest(
                    `${process.env.VUE_APP_BASE_URL}/expenditure/request/store/product`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )

                if (status === 201) {
                    this.submitting = false
                    this.resetProductPurchaseFields()
                    this.successMessage = data.message
                }
            } catch (error) {
                this.submitting = false
                if (error.response) {
                    this.errorMessage = error.response.data.message
                }
            }
        },
        async sendNonProductPurchaseRequest() {
            try {
                const nonProductPurchaseData = Object.values(this.nonProductPurchaseData)

                if (nonProductPurchaseData.includes(null)) {
                    this.errorMessage = 'All fields are required.'

                    return
                }

                if (this.submitting) {
                    return
                }

                this.submitting = true

                const formData = new FormData()

                formData.append('product_name', this.nonProductPurchaseData.itemName)
                formData.append('product_description', this.nonProductPurchaseData.itemDescription)
                formData.append('total_cost', Number(this.nonProductPurchaseData.cost))
                formData.append(
                    'user_id',
                    JSON.parse(localStorage.getItem('lcl_auth_user')).user.id
                )
                formData.append('supporting_documents[]', this.supportDocuments)

                const { status } = await Request.postRequest(
                    'expenditure/request/store/nonproduct',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )

                if (status === 201) {
                    this.submitting = false
                    this.$router.push({ name: 'Expenditure' })
                }
            } catch (error) {
                this.submitting = false
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>
