<template>
    <nav class="mx-32 my-8">
        <h1 class="text-xl font-bold pb-3.5 capitalize tracking-wide">{{ pageTitles.heading }}</h1>
        <div class="flex items-center">
            <img src="@/assets/images/back-arrow.svg" alt="back arrow">
            <span @click="$router.go(-1)" class="ml-2 my-1 text-xs uppercase cursor-pointer" style="color: #828282">{{ pageTitles.content }}</span>
        </div>
    </nav>
</template>

<script>
export default {
    props: {
        pageTitles: Object
    }
}
</script>
